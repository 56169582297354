import React from "react"
import { Link } from "gatsby"

const Navbar = ({ toggleSidebar }) => {
    return (
        <nav className="navbar">
            <div className="nav-center">
                <div className="nav-header">
                    <Link to={"/"} className="logo">Δ Codex</Link>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
